import { GET_ANNOUNCEMENTS } from "../graphql/queries/announcements";
import { useQuery } from "@apollo/client";

export const useAnnouncements = () => {
  const { loading, error, data } = useQuery(GET_ANNOUNCEMENTS);
  // we can change data to a state if we ever wanna setState but I dont think we need to
  return {
    loading,
    data,
    error,
  };
};
