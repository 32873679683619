import { read, utils } from "xlsx";
import { useState, useContext, useEffect } from "react";
import { HadeethContext } from "../contexts/hadeethContext";
import {format} from 'date-fns';

export const Testimonials = (props) => {
  const {data,loading} = useContext(HadeethContext);
  const hadeeth = !loading && data && data.allHadeeth;

  const [todaysHadeeth, setTodaysHadeeth] = useState({});
  var today = format(new Date(), 'M/d/yy');
  
  useEffect(() => {
      if (hadeeth){
          if (!hadeeth.length) return;
          var result = hadeeth[0];
          var url = result.excel_file.asset.url
          
          var req = new XMLHttpRequest();
          req.open("GET", url, true);
          req.responseType = "arraybuffer";


          req.onload = function (e) {
              var data = new Uint8Array(req.response);
              var wb = read(data, { type: "array" });
              var ws = wb.Sheets["Sheet1"];
              var datas = utils.sheet_to_json(ws, { raw: false });
              setTodaysHadeeth(datas.find((obj) => {return obj.Date === today}));
              };
          req.send();
      }
  }, [hadeeth]);

  return (
    <div id='testimonials'>
      <a className="testimonialsAnchor" id="testimonialsAnchor"></a>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Daily Hadeeth</h2>
        </div>
        <div className='row'>
        <div className='col-md-12'>
            <div className='testimonial'>
              <div className='testimonial-content'>
                <a className='testimonial-meta' href={"https://sunnah.com/" + (todaysHadeeth ? todaysHadeeth.Link : '')} target="_blank" rel="noreferrer">
                  <h1 className='compiled'>{todaysHadeeth ? todaysHadeeth.Compiled : 'Sahih Muslim'}</h1>
                  <h5 className='book'>Book: {todaysHadeeth ? todaysHadeeth.Book : 'The book of Pilgrimage'}</h5>
                  <h5 className='reference'>Reference: {todaysHadeeth ? todaysHadeeth.Ref1 + '; ' + todaysHadeeth.Ref2 + '; ' + todaysHadeeth.Ref3 : 'Sahih Muslim 1288 c; Book 15, Hadith 317'}</h5>
                  <p className='narrated'>{todaysHadeeth ? todaysHadeeth.Narrated : 'Shu\'ba reported this hadith with the same chain of transmitters and said:'}</p>
                  <p className='hadith'>"{todaysHadeeth ? todaysHadeeth.Hadith : 'He (the Holy Prophet) observed the two prayers (together) with one iqama.'}"</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
