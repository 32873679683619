import { read, utils } from "xlsx";
import { useState, useContext, useEffect } from "react";
import { TimetablesContext } from "../contexts/timetablesContext";

export const ReadExcel = (props) => {
    const {data,loading} = useContext(TimetablesContext);
    const timetables = !loading && data && data.allTimetable;

    const [todaysData, setTodaysData] = useState({});
    const [pdfURL, setPdfURL] = useState({});
    const [jummahTimes, setJummahTimes] = useState({});
    var today = new Date();
    var d = String(today.getDate());
    var timetableName = new Intl.DateTimeFormat("en-UK", {month: "long",year: "numeric"}).format(today);
    
    useEffect(() => {
        if (timetables){
            var result = timetables.find((obj) => {return obj.title === timetableName});
            if (!result) {
                return (
                    <div>Timetable not uploaded</div>
                )
            }
            var url = result.excel_file.asset.url

            setJummahTimes([result.jummah_1,result.jummah_2,result.jummah_3]);

            setPdfURL(result.pdf_file.asset.url);
            
            var req = new XMLHttpRequest();
            req.open("GET", url, true);
            req.responseType = "arraybuffer";


            req.onload = function (e) {
                var data = new Uint8Array(req.response);
                var wb = read(data, { type: "array" });
                var ws = wb.Sheets["Sheet1"];
                var datas = utils.sheet_to_json(ws, { raw: false });
                setTodaysData(datas[d - 1]);
                };
            req.send();
        }
    }, [timetables]);
        
    return (
        <div className="mainCallToAction">
            <div className="date-wrapper mceNonEditable">
                <a target="_blank" rel="noreferrer noopener" href={pdfURL}>Download Timetable</a>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <div className="gregorian">{new Intl.DateTimeFormat("en-UK", {day: "numeric",month: "long",year: "numeric"}).format(today)}</div>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <div className="hijri">{new Intl.DateTimeFormat("en-UK", {calendar: 'islamicc', day: "numeric",month: "long",year: "numeric"}).format(today)}</div>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <div className="jummah">Jummah</div>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div className="salah-block-content mceNonEditable">
                <div className="salah-times">
                <div className="salah-time-row 15735"><span className="col2"></span> <span className="col3 col-s">Fajr</span> <span className="col4 col-s">Zuhr</span> <span className="col5 col-s">‘Asr</span> <span className="col6 col-s">Maghrib</span> <span className="col7 col-s">‘Ishā</span> <span className="col7 col-s"/> <span className="col7 col-s">1st</span> <span className="col7 col-s">{jummahTimes[0]}</span></div>
                <div className="salah-time-row time-start"><span className="col2">Begins</span> <span className="col3 pi1 col-s fajr">{todaysData['Fajr Start']}</span> <span className="col4 pi2 col-s zuhr">{todaysData['Zuhr Start']}</span> <span className="col5 pi3 col-s asr">{todaysData['Asr Start']}</span> <span className="col6 pi4 col-s maghrib">{todaysData['Magrib']}</span> <span className="col7 col-s isha">{todaysData['Isha Start']}</span> <span className="col7 col-s"/>  <span className="col7 col-s">2nd</span> <span className="col7 col-s">{jummahTimes[1]}</span></div>
                <div className="salah-time-row time-end"><span className="col2">Jamā‘ah</span> <span className="col3 col-s fajr">{todaysData['Fajr Jamaat']}</span> <span className="col4 pi7 col-s zuhr">{todaysData['Zuhr Jamaat']}</span> <span className="col5 col-s asr">{todaysData['Asr Jamaat']}</span> <span className="col6 col-s maghrib">{todaysData['Magrib']}</span> <span className="col7 col-s isha">{todaysData['Isha Jamaat']}</span> <span className="col7 col-s"/>  <span className="col7 col-s">Girls Sch</span> <span className="col7 col-s">{jummahTimes[2]}</span></div>
                </div>
            </div>
        </div>
    );
};
