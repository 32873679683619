import { gql } from "@apollo/client";

export const GET_POSTERS = gql`
  query GetPosters {
    allPosters {
      _id
      _createdAt
      _updatedAt
      title
      posterJPG {
        asset {
          url
        }
      }
      posterPDF {
        asset {
          url
        }
      }
    }
  }
`;
