import { GET_POPUP } from "../graphql/queries/popup";
import { useQuery } from "@apollo/client";

export const usePopup = () => {
  const { loading, error, data } = useQuery(GET_POPUP);
  // we can change data to a state if we ever wanna setState but I dont think we need to
  return {
    loading,
    data,
    error,
  };
};
