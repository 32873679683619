import { ReadExcel } from "./readExcel"

export const Navigation = (props) => {
  function go_to_about() {
    document.getElementById('aboutAnchor').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  function go_to_services() {
    document.getElementById('servicesAnchor').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  function go_to_donate() {
    document.getElementById('donateAnchor').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  function go_to_testimonials() {
    document.getElementById('testimonialsAnchor').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  function go_to_team() {
    document.getElementById('teamAnchor').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  function go_to_contact() {
    document.getElementById('contactAnchor').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  function go_to_top() {
    document.getElementById('header').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <a href onClick={go_to_top} className='navbar-brand page-scroll'>
          <img src="img/Al-Madani Masjid.svg" className="header-img" alt="" />
        </a>
        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ReadExcel />
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href onClick={go_to_about} className='page-scroll'>
                About
              </a>
            </li>
            <li>
              <a href onClick={go_to_services} className='page-scroll'>
                Programmes
              </a>
            </li>
            <li>
              <a href onClick={go_to_donate} className='page-scroll'>
                Donate
              </a>
            </li>
            <li>
              <a href onClick={go_to_testimonials} className='page-scroll'>
                Daily Hadeeth
              </a>
            </li>
            <li>
              <a href onClick={go_to_team} className='page-scroll'>
                Education
              </a>
            </li>
            <li>
              <a href onClick={go_to_contact} className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
