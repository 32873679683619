import { gql } from "@apollo/client";

export const GET_POPUP = gql`
  query GetPopup {
    allPopup {
      _id
      _createdAt
      _updatedAt
      popupImage {
        asset {
          url
        }
      }
    }
  }
`;
