import { useContext, useState } from "react";
import Carousel from "react-spring-3d-carousel";
import { PostersContext } from "../contexts/postersContext";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export const Services = () => {
  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 4,
    showNavigation: true,
    config: config.wobbly,
  });

  // manually call click action at given intervals

  const { data, loading } = useContext(PostersContext);
  const posters = !loading && data.allPosters;
  // store the library carousel functions in a state variable to be manipulated when we need
  //gotoslide is set to 0 and define, offset radius defines how many items we see in the carousel, config is the animation style, shownavigation is for the carousel buttons

  if (!posters.length) return;
  const slides = posters.map((poster, index) => ({
    key: uuidv4(),
    content: <a target="_blank" rel="noreferrer noopener" href={poster.posterPDF ? poster.posterPDF.asset.url : ''} style={poster.posterPDF ? {"height":"100%", "alignItems":"center", "display":"flex"} : {"pointerEvents":"none","height":"100%", "alignItems":"center"}}><img src={poster.posterJPG.asset.url} alt={index.toString()} style={{"width":"80vw"}}/></a>,
  }));
  slides.map((slide, index) => {
    return { ...slide, onClick: () => setState({ goToSlide: index }) };
  });

  // generate a list of carousel components with uuids, (unique keys), and using map which is like a for loop apply all  the slides to the imported carousel, gotoslide is set to the index of the slide clicked
  // the onclick listener is to ensure navigation works correctly

  return (
    <div id="services" className="text-center">
      <a className="servicesAnchor" id="servicesAnchor"></a>
      <div className="container">
        <div className="section-title">
          <h2>Programmes and Appeals</h2>
          <h5>Click on the image to download pdf</h5>
          <div
            id="caro"
            style={{
              width: "auto",
              height: "60vh",
              margin: "0 auto",
            }}
          >
            <Carousel
              goToSlideDelay={200}
              slides={slides}
              offsetRadius={state.offsetRadius}
              showNavigation={state.showNavigation}
              animationConfig={state.config}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
