import { GET_TIMETABLES } from "../graphql/queries/timetables";
import { useQuery } from "@apollo/client";

export const useTimetables = () => {
  const { loading, error, data } = useQuery(GET_TIMETABLES);
  // we can change data to a state if we ever wanna setState but I dont think we need to
  return {
    loading,
    data,
    error,
  };
};
