import { useContext } from "react";
import { AnnouncementsContext } from "../contexts/announcementsContext";

export const Footer = () => {
  const { data, loading } = useContext(AnnouncementsContext);

  const announcements = !loading && data.allAnnouncement;
  return (
    <div className="navbar navbar-fixed-bottom footer-wrapper marquee">
      <h1 className="marquee__content">{announcements && announcements.map((a) => a.title + "  *  ")}</h1>
      <h1 aria-hidden="true" className="marquee__content">{announcements && announcements.map((a) => a.title + "  *  ")}</h1>
    </div>
  );
};
