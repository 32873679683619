export const Education = (props) => {
  return (
    <div id='team' className='text-center'>
      <a className="teamAnchor" id="teamAnchor"></a>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Education</h2>
          <p>
            Abu Hurairah reported the Prophet (ﷺ) as saying:<br/>
            If anyone pursues a path in search of knowledge, Allah will thenby make easy for him a path to paradise;<br/>
            Sunan Abi Dawud
          </p>
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <a href={d.link} target="_blank" rel="noreferrer" className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='team-img' />
                    <div className='caption'>
                      <h4>{d.name}</h4>
                    </div>
                  </a>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
