import { useContext, useState } from "react";
import Cookies from 'js-cookie';
import { PopupContext } from "../contexts/popupContext";
import Popup from 'reactjs-popup';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export const Popups = () => {
  const [open, setOpen] = useState(Cookies.get('visit')!=='true');
  const closeModal = () => {
    setOpen(false);
    Cookies.set('visit', true, {expires: 1});
  };

  const { data, loading } = useContext(PopupContext);
  const popup = !loading && data.allPopup;
  if (!popup.length) return;

  if (popup[0]){
    return (
      <Popup open={open} closeOnDocumentClick onClose={closeModal} position="center center">
            <div>
              <a className="close" onClick={closeModal}>
                &times;
              </a>
              <img className="popupImage" src={popup[0].popupImage.asset.url}/>
            </div>
      </Popup>
    );
  }
  return (null);
};
