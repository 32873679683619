import { gql } from "@apollo/client";

export const GET_HADEETH = gql`
    query GetHadeeth {
        allHadeeth {
            _id
            _createdAt
            _updatedAt
            excel_file {
                asset {
                    url
                }
            }
        }
    }
`;